import React from "react"
import Navbar from './Navbar';

import { library } from '@fortawesome/fontawesome-svg-core'
import { fab } from '@fortawesome/free-brands-svg-icons'
import { faCheckSquare, faCoffee } from '@fortawesome/free-solid-svg-icons'

library.add(fab, faCheckSquare, faCoffee)

class Layout extends React.Component {
  render() {
    const { title, children } = this.props
    
    return (
      <>
        <Navbar title={title} />
        <div className="container">
          <main className="mt-5 col-md-10 col-lg-8 offset-md-1 offset-lg-2">
            {children}
          </main>
        </div>
      </>
    )
  }
}

export default Layout;
