import React, { useState } from 'react';
import { Link } from "gatsby"
import './Navbar.scss';
import CoffeeSvg from '../../../content/assets/coffee.svg';
import Logo from '../../../content/assets/misscoded-logo.png';

const Navbar = () => {
  const [menuOpen, setMenuOpen] = useState(false);
  
  return (
    <nav className="navbar sticky-top navbar-expand-sm navbar-light">
      <Link className="navbar-brand" to="/">
        <img src={Logo} height="25px" alt="misscoded logo" />
        {/* <span className="text-primary">miss</span><span>coded</span>   */}
      </Link>
      <button className="navbar-toggler" type="button" onClick={() => setMenuOpen(!menuOpen)}>
        <span className="navbar-toggler-icon"></span>
      </button>

      <div className={`${menuOpen ? 'show' : 'collapse'} navbar-collapse`} id="navbarTogglerDemo02">
        <ul className="navbar-nav ml-auto mt-2 mt-lg-0">
          <li className="nav-item">
            {/* TODO :: blog endpoint => articles */}
            <Link className="nav-link" to="/blog">Articles</Link>
          </li>
          <li className="nav-item">
            <Link className="nav-link" to="/about">About Me</Link>
          </li>
          <li className="nav-item">
            <a className="btn btn-secondary" title="Buy me a coffee! ❤" target="_blank" rel="noopener noreferrer" href="https://ko-fi.com/misscoded">
              <img src={CoffeeSvg} height="40px" alt="Buy me a coffee!" />
            </a>
          </li>
        </ul>
      </div>
    </nav>
  )
}

export default Navbar;